<template>
  <div class="news Mediareport titlesTop" v-if="navData">
    <headTitle :title="$route.query.name" />
    <slidepage
      :navData="navData"
      @indexChanged="indexChanged"
      @navChange="navChange"
      :navStyle="navStyle"
      :designatedLocation="designatedLocation"
    >
      <template v-for="(item,index) in navData" v-slot:[slide+index]>
        <div :key="index" class="height">
          <MediareportList :myIndex="index" :datalink="item" :currentdisplay="nowBlock"></MediareportList>
        </div>
      </template>
    </slidepage>
  </div>
</template>

<script>

import slidepage from "./SlidePage";
import MediareportList from "./MediareportList";
import Homeapi from "@/api/home";
import { Toast } from 'vant';
export default {
  data() {
    return {
      slide: 'slide',
      navData: [], // 导航条数据
      navStyle: { background: "#fff" }, //导航背景
      params: {
        appName: this.appName,
      },
      isrefresh: false,
      statistics: null,
      middleNav: null,
      progressivedata: null,
      designatedLocation: 0,
      nowBlock: null,
      dataLink: '',
      newPath: '',
    };
  },
  components: {
    slidepage,
    [Toast.name]: Toast,
    MediareportList
  },
  beforeRouteEnter(to, from, next) {
    console.log('111', from);
    next(vm => {
      // 通过 `vm` 访问组件实例,将值传入newPath
      //beforeRouteEnter守卫在导航确认前被调用，因此守卫不能访问 this
      //在template里已经可以用this.newPath来获取到from所带的name值
      vm.newPath = from.name
      if (from.name == 'home') {
        vm.nowBlock = 0
        vm.designatedLocation = 0
      } else {
        if (localStorage.getItem('indexNav')) {
          console.log('123', Number(localStorage.getItem('indexNav')));
          vm.nowBlock = Number(localStorage.getItem('indexNav'))
          vm.designatedLocation = Number(localStorage.getItem('indexNav'))

        }
      }
    })
  },

  created() {

  },
  mounted() {
    let that = this
    if (that.$route.query.columnId) {
      that.dataLink = that.$route.query.datalink;
      that.columnId = that.$route.query.columnId;

      this.navigationData()
    } else {
      that.$router.push({
        path: '/home'
      })
    }
  },
  methods: {
    // 导航改变了
    indexChanged(index) {
      console.log('daohanglan', index);
      this.nowBlock = index
      if (localStorage.getItem('indexNav')) {
        localStorage.removeItem('indexNav')
        localStorage.setItem('indexNav', index)
      } else {
        localStorage.setItem('indexNav', index)
      }

    },
    navChange(allData) {
      this.blockNav = allData;
      this.isrefresh = false;
      this.$nextTick(() => {
        this.isrefresh = true;
      });
    },
    // 接口api 
    navigationData() {
      let that = this
      let params = {
        appName: this.appName,
        columnId: that.columnId
      }
      Homeapi.getColumnWithNav(params, that.dataLink).then(res => {
        if (res.success) {
          console.log('导航数据', res.data)
          that.navData = res.data
        } else {
          Toast.fail(res.message)
        }
      })
    },
  },
};
</script>

<style>
/* .Mediareport .mescroll-empty {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 20vw;
} */
</style>
<style scoped>
.height {
  height: 100%;
}
.indexList {
  width: 100%;
  height: 100%;
  position: relative;
}
.swiper-container {
  --swiper-pagination-color: #1377e2;
}
.indexList .swiper-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.swiperBox {
  width: 100%;
  padding-bottom: 60%;
  position: relative;
}
.outLinkerContent {
  width: 100%;
  padding-bottom: 20%;
  position: relative;
  /* margin-top: 15px; */
}
.outLinkerContentBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.outLinkerPer {
  width: 20%;
  height: 80%;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.outLinkerPerPer {
  width: 100%;
  height: 56%;
}
.outLinkerPerPer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.outLinkerPerTitle {
  width: 100%;
  /* line-height: 25px; */
  font-size: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}
.class_body {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  margin: 0.21rem 0.28rem;
}
.class_list {
  border-radius: 5px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
  font-size: 0.13rem;
}
.class_content {
  /* width: 28vw; */
  /* text-align: center; */
  /* margin: 2vw 0; */
  font-size: 0.26rem;
  /* font-weight: bold; */
}
.class_btn {
  width: 16vw;
  background: #f61729;
  color: #fff;
  padding: 1px 5px;
  border-radius: 7px;
}
.class_list img {
  width: 9vw;
}
.data_all {
  height: 2.28rem;
  margin: 0 0.28rem 4vw;
  background: #ffffff;
  box-shadow: 1px 4px 3px 0px rgba(114, 44, 36, 0.05);
  border-radius: 10px;
}
.data_all img {
  width: 100%;
}
.sum_body {
  display: flex;
  justify-content: space-around;
  border-radius: 8px;
  margin-top: -3vw;
  padding: 0.2rem;
}
.sum_list {
  width: 20vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 2vw 0;
  border-right: 1px solid #bfbfbf;
  padding: 0 5px;
}
.sum_list:last-child {
  border-right: 0;
}
.sum_content {
  margin: 1vw 0;
  font-size: 0.21rem;
  font-weight: bold;
}
.wenming_img {
  display: flex;
  align-items: center;
  font-size: 0.31rem;
  font-weight: bold;
  padding: 2vw 3vw;
}
.wenming_img img {
  width: 6vw;
  margin-right: 5px;
}
.wenming_body {
  display: flex;
  justify-content: space-around;
}
.wenming_wrapper {
  position: relative;
}
.wenming_list {
  width: 43vw;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2vw;
  border: 1px solid #dcdcdc;
}
.zhaomu_img {
  width: 17vw;
  position: absolute;
  top: 0;
}
.wenming_list img {
  width: 100%;
}
.wenming_content {
  /* width: 28vw; */
  /* text-align: center; */
  margin: 0.1rem 0;
  font-size: 0.3rem;
  padding-left: 2vw;
  font-weight: bold;
}
.wenming_content_two {
  margin: 0.1rem 0;
  font-size: 0.23rem;
  padding-left: 2vw;
  color: #939393;
}
.indexList .haveContent {
  width: 100%;
  height: 100%;
}
.indexList .thumbImg {
  width: 100%;
  height: 100%;
  position: relative;
}
.indexList .thumbImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.indexList .swiper-pagination-bullet {
  width: 20px;
  height: 3px;
  border-radius: 0px;
  background: #ababab;
  opacity: 1;
}
.indexList .swiper-pagination-bullet-active {
  background: #fff;
}
.indexList .titleBunner {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 0px;
  color: #333;
  font-size: 0.28rem;
  font-weight: 700;
  padding: 0px 10px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #e6e6e6;
  padding-top: 5px;
}
.titleIndex {
  position: absolute;
  bottom: 0;
  right: 5px;
  font-size: 0.24rem;
  color: #ababab;
}
.titleIndex span {
  color: red;
  font-weight: 100;
}
.borderBox {
  /* border:  solid#e6e6e6; */
  /* width: 100%; */
  /* margin-top: -50px; */
}
/* .newsBox{
        margin-top: 18px;
    } */
.outSwiper {
  /* margin-top: 5px; */
  margin: 10px auto;
}
.my-swipe .van-swipe-item {
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.imgBox {
  /* margin: 0 1px; */
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.imgBox img {
  width: 35px;
  height: 35px;
}
.outerTitle {
  font-size: 14px;
}
</style>